var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
    left: _vm.next,
    right: _vm.prev,
    start: _vm.stopEvent,
    end: _vm.stopEvent,
  }),expression:"{\n    left: next,\n    right: prev,\n    start: stopEvent,\n    end: stopEvent,\n  }"}],staticClass:"base-carousel",class:_vm.classes},[_c('div',{staticClass:"base-carousel__container"},[_vm._t("default"),_vm._v(" "),(!_vm.hideArrows)?_c('div',{staticClass:"base-carousel__prev",attrs:{"role":"button"},on:{"click":_vm.prev}},[_c('base-icon',{attrs:{"mdi":"chevron-left"}})],1):_vm._e(),_vm._v(" "),(!_vm.hideArrows)?_c('div',{staticClass:"base-carousel__next",attrs:{"role":"button"},on:{"click":_vm.next}},[_c('base-icon',{attrs:{"mdi":"chevron-right"}})],1):_vm._e(),_vm._v(" "),(!_vm.hideControl)?_c('div',{staticClass:"base-carousel__controls"},_vm._l((_vm.itemCount),function(i){return _c('div',{key:i,class:{
          'base-carousel__controls-item--active': i - 1 === _vm.internalValue,
        },attrs:{"role":"button"},on:{"click":function($event){return _vm.changeItemPos(i - 1)}}})}),0):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }