<template>
  <div
    v-touch="{
      left: next,
      right: prev,
      start: stopEvent,
      end: stopEvent,
    }"
    class="base-carousel"
    :class="classes"
  >
    <div class="base-carousel__container">
      <slot></slot>

      <div
        v-if="!hideArrows"
        class="base-carousel__prev"
        role="button"
        @click="prev"
      >
        <base-icon mdi="chevron-left"></base-icon>
      </div>
      <div
        v-if="!hideArrows"
        class="base-carousel__next"
        role="button"
        @click="next"
      >
        <base-icon mdi="chevron-right"></base-icon>
      </div>
      <div v-if="!hideControl" class="base-carousel__controls">
        <div
          v-for="i in itemCount"
          :key="i"
          role="button"
          :class="{
            'base-carousel__controls-item--active': i - 1 === internalValue,
          }"
          @click="changeItemPos(i - 1)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'BaseCarousel',
  components: { BaseIcon },
  provide() {
    return {
      carousel: this,
    }
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: undefined,
    },
    hideArrows: {
      type: Boolean,
      default: false,
    },
    hideControl: {
      type: Boolean,
      default: false,
    },
    hideArrowsXs: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    cycle: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: [Number, String],
      default: 6000,
    },
  },
  data() {
    return {
      internalLazyValue: this.value,
      items: [],
      transitionName: 'base-window-x-reverse-transition',
      slideTimeout: undefined,
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.internalLazyValue
      },
      set(val) {
        if (val === this.internalLazyValue) return

        this.internalLazyValue = val

        this.$emit('input', val)
      },
    },
    classes() {
      return {
        'base-carousel--hide-arrows-xs': this.hideArrowsXs,
        'base-carousel--full-width': this.fullWidth,
      }
    },
    wrapperStyles() {
      return {
        height: this.height,
      }
    },
    itemCount() {
      return this.items.length
    },
  },
  watch: {
    internalValue: 'restartTimeout',
    interval: 'restartTimeout',
    value(val) {
      this.internalLazyValue = val
    },
    cycle(val) {
      if (val) {
        this.restartTimeout()
      } else {
        clearTimeout(this.slideTimeout)
        this.slideTimeout = undefined
      }
    },
  },
  mounted() {
    this.startTimeout()
  },
  methods: {
    next() {
      let nextIndex = this.internalValue + 1

      if (nextIndex >= this.itemCount) {
        nextIndex = 0
      }

      this.changeItem(this.items[nextIndex])
    },
    prev() {
      let prevIndex = this.internalValue - 1

      if (prevIndex < 0) {
        prevIndex = this.itemCount - 1
      }

      this.changeItem(this.items[prevIndex])
    },
    changeItem(item) {
      const oldTab = this.internalValue
      const newTab = this.items.indexOf(item)

      this.transitionName =
        oldTab > newTab
          ? 'base-window-x-reverse-transition'
          : 'base-window-x-transition'
      this.internalValue = newTab
    },
    changeItemPos(pos) {
      const item = this.items[pos]

      if (item) {
        this.changeItem(item)
      }
    },

    isItemActive(item) {
      return this.items.indexOf(item) === this.internalValue
    },
    registerItem(item) {
      this.items.push(item)
    },
    unregisterItem(item) {
      if (this._isDestroyed) return

      const index = this.items.indexOf(item)

      this.items.splice(index, 1)
    },
    stopEvent(e) {
      e.stopPropagation()
    },
    restartTimeout() {
      this.slideTimeout && clearTimeout(this.slideTimeout)
      this.slideTimeout = undefined

      window.requestAnimationFrame(this.startTimeout)
    },
    startTimeout() {
      if (!this.cycle) return

      this.slideTimeout = window.setTimeout(
        this.next,
        +this.interval > 0 ? +this.interval : 6000
      )
    },
  },
}
</script>
