var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-carousel',{staticClass:"cms-element-image-slider",attrs:{"full-width":"","hide-arrows-xs":"","interval":_vm.interval,"cycle":_vm.cycle},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,i){return _c('base-carousel-item',{key:i,staticStyle:{"z-index":"0"},attrs:{"lazy":i !== 0}},[_c('cms-util-link',{staticClass:"cms-element-image-slider__full-link",attrs:{"url":item.url}}),_vm._v(" "),(item.content)?_c('cms-element-text',{staticClass:"cms-element-image-slider__text",style:(item.textStyle),attrs:{"content":item.content}}):_vm._e(),_vm._v(" "),(item.isVideo)?[(_vm.selectedItem === i)?_c('video',{staticClass:"cms-element-image-slider__video",attrs:{"loop":"","autoplay":"","playsinline":"","muted":"","poster":_vm.buildProxyImageUrlHelper(
            (_vm.$vuetify.breakpoint.smAndDown && !item.mobileMedia) ||
              _vm.$vuetify.breakpoint.mdAndUp
              ? item.mainMedia
              : item.mobileMedia,
            _vm.$vuetify.breakpoint.smAndDown ? 960 : 1920
          )},domProps:{"muted":true}},[(_vm.booted)?[(
              (_vm.$vuetify.breakpoint.smAndDown && !item.mobileVideoUrl) ||
              _vm.$vuetify.breakpoint.mdAndUp
            )?_c('source',{attrs:{"src":item.mainVideoUrl,"type":"video/mp4"}}):_c('source',{attrs:{"type":"video/mp4","src":item.mobileVideoUrl}}),_vm._v(" "),_c('base-image',{attrs:{"media":(_vm.$vuetify.breakpoint.smAndDown && !item.mobileMedia) ||
              _vm.$vuetify.breakpoint.mdAndUp
                ? item.mainMedia
                : item.mobileMedia,"proxy-srcset":[400, 600, 800, 1000, 1500, 1920],"sizes":"100vw","alt":item.alt}})]:_vm._e()],2):_vm._e()]:_c('picture',{staticClass:"d-flex"},[_c('source',{attrs:{"media":"(min-width: 960px)","srcset":_vm.buildProxyUrl(item.mainMedia, [1000, 1500, 1920]),"sizes":"100vw"}}),_vm._v(" "),_c('source',{attrs:{"srcset":_vm.buildProxyUrl(
            item.mobileMedia || item.mainMedia,
            [400, 600, 800, 960]
          ),"sizes":"100vw"}}),_vm._v(" "),_c('base-image',{attrs:{"init-width":1920,"media":item.mainMedia,"alt":item.alt}})],1)],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }