<template>
  <base-carousel
    v-model="selectedItem"
    full-width
    hide-arrows-xs
    class="cms-element-image-slider"
    :interval="interval"
    :cycle="cycle"
  >
    <base-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :lazy="i !== 0"
      style="z-index: 0"
    >
      <cms-util-link
        class="cms-element-image-slider__full-link"
        :url="item.url"
      ></cms-util-link>
      <cms-element-text
        v-if="item.content"
        :content="item.content"
        class="cms-element-image-slider__text"
        :style="item.textStyle"
      ></cms-element-text>
      <template v-if="item.isVideo">
        <video
          v-if="selectedItem === i"
          loop
          autoplay
          playsinline
          muted
          class="cms-element-image-slider__video"
          :poster="
            buildProxyImageUrlHelper(
              ($vuetify.breakpoint.smAndDown && !item.mobileMedia) ||
                $vuetify.breakpoint.mdAndUp
                ? item.mainMedia
                : item.mobileMedia,
              $vuetify.breakpoint.smAndDown ? 960 : 1920
            )
          "
        >
          <template v-if="booted">
            <source
              v-if="
                ($vuetify.breakpoint.smAndDown && !item.mobileVideoUrl) ||
                $vuetify.breakpoint.mdAndUp
              "
              :src="item.mainVideoUrl"
              type="video/mp4"
            />
            <source v-else type="video/mp4" :src="item.mobileVideoUrl" />
            <base-image
              :media="
                ($vuetify.breakpoint.smAndDown && !item.mobileMedia) ||
                $vuetify.breakpoint.mdAndUp
                  ? item.mainMedia
                  : item.mobileMedia
              "
              :proxy-srcset="[400, 600, 800, 1000, 1500, 1920]"
              sizes="100vw"
              :alt="item.alt"
            />
          </template>
        </video>
      </template>
      <picture v-else class="d-flex">
        <source
          media="(min-width: 960px)"
          :srcset="buildProxyUrl(item.mainMedia, [1000, 1500, 1920])"
          sizes="100vw"
        />
        <source
          :srcset="
            buildProxyUrl(
              item.mobileMedia || item.mainMedia,
              [400, 600, 800, 960]
            )
          "
          sizes="100vw"
        />
        <base-image
          :init-width="1920"
          :media="item.mainMedia"
          :alt="item.alt"
        />
      </picture>
    </base-carousel-item>
  </base-carousel>
</template>

<script>
import BaseCarousel from '~/components/base/carousel/BaseCarousel'
import BaseCarouselItem from '~/components/base/carousel/BaseCarouselItem'
import CmsElementText from '~/components/cms/elements/CmsElementText'
import { isInDateRange } from '~/helpers'
import BaseImage from '~/components/base/image/BaseImage'
import { buildProxyImageUrl } from '~/helpers/proxy/buildProxyImageUrl'

export default {
  name: 'CmsElementImageSlider',
  components: {
    BaseImage,
    CmsElementText,
    BaseCarouselItem,
    BaseCarousel,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedItem: 0,
      booted: false,
    }
  },
  computed: {
    sliderImages() {
      return this.content.data?.sliderItems || []
    },
    items() {
      return this.sliderImages
        .filter((item) => {
          return isInDateRange(item.publishFrom, item.publishTo)
        })
        .map((item) => {
          return {
            content: {
              data: {
                content: item.content,
              },
            },
            url: item.url,
            isVideo: !!item.mainVideoMedia,
            mainMedia: item.mainMedia,
            mobileMedia: item.mobileMedia,
            mainVideoUrl: item.mainVideoMedia?.url,
            alt: item.mainMedia.alt,
            mobileVideoUrl: item.mobileVideoMedia?.url,
            textStyle: this.buildTextStyle(item),
          }
        })
    },
    cycle() {
      return this.content.config?.cycle?.value || false
    },
    interval() {
      return this.content.config?.interval?.value || 6000
    },
  },
  mounted() {
    this.booted = true
  },
  methods: {
    buildTextStyle(item) {
      return {
        top: item.posTop || undefined,
        left: item.posLeft || undefined,
        right: item.posRight || undefined,
        bottom: item.posBottom || undefined,
      }
    },
    buildProxyImageUrlHelper(media, size) {
      return buildProxyImageUrl(this.$config, media.url, { w: size })
    },
    buildProxyUrl(media, srcsets) {
      if (!media.url) {
        return
      }

      return srcsets
        .map((w) => {
          return buildProxyImageUrl(this.$config, media.url, { w }) + ` ${w}w`
        })
        .join(', ')
    },
  },
}
</script>
